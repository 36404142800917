import Category from '../../domain/Categories/Category';
import CategoryRepositoryInterface from '../../domain/Categories/CategoryRepositoryInterface';

class FetchCategoryBySlugWithChildren {
  private _repository: CategoryRepositoryInterface;

  constructor(repository: CategoryRepositoryInterface) {
    this._repository = repository;
  }

  execute(slug: string): Promise<Category> {
    return this._repository.fetchBySlugWithChildren(slug);
  }
}

export default FetchCategoryBySlugWithChildren;
