import Factory from '@src/core/Factory';
import FetchCategoryBySlugWithChildren from './FetchCategoryBySlugWithChildren';

class FetchCategoryBySlugWithChildrenFactory {
  private static _instance: FetchCategoryBySlugWithChildren | undefined;

  static create(): FetchCategoryBySlugWithChildren {
    if (!this._instance) {
      this._instance = new FetchCategoryBySlugWithChildren(Factory.newCategoryRepository());
    }

    return this._instance;
  }

  static setInstance(instance: FetchCategoryBySlugWithChildren): void {
    this._instance = instance;
  }
}

export default FetchCategoryBySlugWithChildrenFactory;
